import {
  ButtonPrimaryLink,
  device,
  EmphasizedText,
  SectionHeader,
  Subheading,
  TextBody
} from "@xolvio/xolvio-ui";
import styled from "styled-components";
import React from "react";

export const ServiceSection = props => (
  <ServiceSectionWrapper>
    <ServiceContentWrapper>
      <IconWrapper>{props.icon}</IconWrapper>
      <SubheadingStyled>{props.subheading}</SubheadingStyled>
      <SectionHeader style={{ margin: "12px auto" }}>
        <EmphasizedText
          text={props.heading}
          highlight={props.headingHighlights}
        />
      </SectionHeader>

      <TextBody style={{ margin: "0 0 18px" }}>{props.description}</TextBody>
      <TextBody style={{ margin: "0 0 48px" }}>{props.description2}</TextBody>

      {props.url && (
        <ButtonPrimaryLink
          onClick={props.onClick}
          href={props.onClick ? undefined : props.url}
          as={"a"}
        >
          <TextBody color={"white"} style={{ padding: 0, margin: "0 auto" }}>
            {props.buttonText || "Learn more"}
          </TextBody>
        </ButtonPrimaryLink>
      )}
    </ServiceContentWrapper>
  </ServiceSectionWrapper>
);

const ServiceSectionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.tabletVertical} {
    flex-direction: row;
  }
`;

const SubheadingStyled = styled(Subheading)`
  margin: 20px 0;
`;

const IconWrapper = styled.div`
  @media ${device.tabletVertical} {
    position: relative;
    top: -10px;
    width: 100px;
    height: 100%;
    display: block;
  }
`;

const ServiceContentWrapper = styled.div`
  text-align: center;

  @media ${device.tabletVertical} {
    text-align: left;

    > * {
      text-align: left;
    }
  }
`;
