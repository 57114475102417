// TODO
// @Sam rewrite all that
// @Łukasz - replace blog.jpg and articles.jpg in static/assets/images
//    - after rebuilding/restarting gatsby new images should show up

export const blogPageContent = {
  tout: "Peek into our quality faster mindset",
  toutHighlight: "mindset",
  toutBody: `See for yourself how we think about problems both from a high-level strategy and a low-level code point of view, and how it all fits together.`,

  firstSection: {
    imagePosition: "right",
    heading: "Articles",
    headingHighlights: "Art",
    subheading: "",
    description: `Our articles focus on high-level big picture problems where we explore domains that are prevalent and 
    current in the industry. They are aimed at all team members that work within the product development lifecycle.`,
    description2: `From fundamental first-principles to the meaning of quality, and from bug prevention strategies, to
    using CMS's in CI/CD pipelines. We hope you'll enjoy the big ideas within this section.`,
    icon: null,
    url: "/blog/articles",
    sectionId: "articles",
    buttonText: "Read More"
  },

  secondSection: {
    url: "/blog/dev-notes",
    imagePosition: "left",
    heading: "Dev Notes",
    headingHighlights: "Dev",
    subheading: "",
    description: `Here you will find the low-level detailed view of our development practices in action where we share 
    our learnings as we work with our clients. This section is aimed mainly at developers and technical people.`,
    description2: `You will read about a variety of subjects which include but are not limited to TypeScript, JavaScript,
    React, AWS, CDK, Testing, Mocking, Jest, Apollo, GraphQL - and more.`,
    icon: null,
    sectionId: "articles",
    buttonText: "Read More"
  },

  latestPosts: {
    subheading: "WHAT WE'VE WRITTEN ABOUT ON LATELY",
    header: "Recent Posts"
  }
};

export default blogPageContent;
