import {
  CenteredContentWrapper,
  EmphasizedText,
  ImageConfettiWrapper,
  SectionHeader,
  Subheading,
  TwoColumnContentNodeWrapper,
  TwoColumnsWithImage
} from "@xolvio/xolvio-ui";
import pageData from "../../pages-content/blog";
import React from "react";
import { LayoutDefault } from "../../components/LayoutDefault";
import {
  ExtraSpacing,
  Spacing,
  ToutContent,
  ToutHeader,
  ToutTextBody,
  ToutWrapper
} from "../../components/Services";
import BackgroundImage from "gatsby-background-image";
import { ServiceSection } from "../../components/Services/SectionWithIcon";
import { graphql, StaticQuery } from "gatsby";
import Posts from "../../components/blog/Posts/Posts";
import { BlogWrapper } from "../../components/Homepage";

const BlogPage = ({ data }) => (
  <LayoutDefault>
    <ToutWrapper>
      <ToutContent>
        <ToutHeader>
          <EmphasizedText
            text={pageData.tout}
            highlight={pageData.toutHighlight}
          />
        </ToutHeader>
        <ToutTextBody style={{textAlign: "center"}}>{pageData.toutBody}</ToutTextBody>
      </ToutContent>
    </ToutWrapper>

    <Spacing />

    <TwoColumnsWithImage
      imagePosition={pageData.firstSection.imagePosition}
      imageNode={
        <ImageConfettiWrapper
          flipConfetti
          elementWithBackgroundImage={
            <BackgroundImage
              fluid={data.articles.childImageSharp.fluid}
              durationFadeIn={200}
              style={{
                backgroundPosition: "left center"
              }}
            />
          }
        />
      }
      contentNode={
        <TwoColumnContentNodeWrapper>
          <ServiceSection {...pageData.firstSection} />
        </TwoColumnContentNodeWrapper>
      }
    />

    <Spacing />
    <ExtraSpacing />

    <TwoColumnsWithImage
      imagePosition={pageData.secondSection.imagePosition}
      imageNode={
        <ImageConfettiWrapper
          elementWithBackgroundImage={
            <BackgroundImage
              fluid={data.blog.childImageSharp.fluid}
              durationFadeIn={200}
              style={{
                backgroundPosition: "center center"
              }}
            />
          }
        />
      }
      contentNode={
        <TwoColumnContentNodeWrapper>
          <ServiceSection {...pageData.secondSection} />
        </TwoColumnContentNodeWrapper>
      }
    />

    <BlogWrapper>
      <Subheading>{pageData.latestPosts.subheading}</Subheading>
      <SectionHeader>{pageData.latestPosts.header}</SectionHeader>
    </BlogWrapper>

    <CenteredContentWrapper style={{ marginBottom: 48 }}>
      <Posts small />
    </CenteredContentWrapper>
  </LayoutDefault>
);

export const Blog = () => (
  <StaticQuery
    query={graphql`
      query {
        articles: file(relativePath: { eq: "articles.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        blog: file(relativePath: { eq: "dev_notes.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      return <BlogPage data={data} />;
    }}
  />
);

export default Blog;
